import { createContext } from 'react'
import { LocalizationState } from './Localization.types'

const initialState: LocalizationState = {
  language: 'ENG',
  updateLanguage: () => null,
}

const LocalizationContext = createContext<LocalizationState>(initialState)

export default LocalizationContext
