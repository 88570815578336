import React, { useReducer } from 'react'
import PropTypes from 'prop-types'

import UserContext from './User.context'
import UserReducer from './User.reducer'
import { UPDATE_USER, UserState } from './User.types'

// Types
import { UserModel } from '../../models'

interface Props {
  children?: React.ReactNode
}

const UserProvider: React.FC<Props> = ({ children }) => {
  let initialState: UserState = {
    user: null,
    updateUser: () => null,
  }

  const [state, dispatch] = useReducer(UserReducer, initialState)

  const updateUser = (user: null | Partial<UserModel>) => {
    dispatch({ type: UPDATE_USER, payload: user })
  }

  initialState = {
    ...initialState,
    updateUser,
  }
  return (
    <UserContext.Provider
      value={{
        user: state.user,
        updateUser,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

UserProvider.propTypes = {
  children: PropTypes.element.isRequired,
}

export default UserProvider
