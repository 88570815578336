import React, { useReducer } from 'react'
import PropTypes from 'prop-types'

import LocalizationContext from './Localization.context'
import LocalizationReducer from './Localization.reducer'
import { UPDATE_LANGUAGE, LocalizationState } from './Localization.types'

// Types
import { ValidLanguage } from '../../types'

interface Props {
  children?: React.ReactNode
}

const LocalizationProvider: React.FC<Props> = ({ children }) => {
  let initialState: LocalizationState = {
    language: 'ENG',
    updateLanguage: () => null,
  }

  const [state, dispatch] = useReducer(LocalizationReducer, initialState)

  const updateLanguage = (language: ValidLanguage) => {
    dispatch({ type: UPDATE_LANGUAGE, payload: language })
  }
  initialState = {
    ...initialState,
    updateLanguage,
  }
  return (
    <LocalizationContext.Provider
      value={{
        language: state.language,
        updateLanguage,
      }}
    >
      {children}
    </LocalizationContext.Provider>
  )
}

LocalizationProvider.propTypes = {
  children: PropTypes.element.isRequired,
}

export default LocalizationProvider
